import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessagePopup = _resolveComponent("MessagePopup")!
  const _component_Horizontal = _resolveComponent("Horizontal")!
  const _component_Burger = _resolveComponent("Burger")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "message" }, {
      default: _withCtx(() => [
        (_ctx.messages.length > 0)
          ? (_openBlock(), _createBlock(_component_MessagePopup, {
              key: 0,
              colors: _ctx.palettes[_ctx.theme],
              status: _ctx.messages[0].status,
              message: _ctx.messages[0].message
            }, null, 8, ["colors", "status", "message"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.device === _ctx.Device.Desktop)
      ? (_openBlock(), _createBlock(_component_Horizontal, {
          key: 0,
          colors: _ctx.palettes[_ctx.theme],
          currentRoute: _ctx.viewSettings.currentPage,
          pages: _ctx.pages,
          theme: _ctx.theme,
          onToggleTheme: _ctx.toggleTheme
        }, null, 8, ["colors", "currentRoute", "pages", "theme", "onToggleTheme"]))
      : (_openBlock(), _createBlock(_component_Burger, {
          key: 1,
          colors: _ctx.palettes[_ctx.theme],
          currentRoute: _ctx.viewSettings.currentPage,
          pages: _ctx.pages,
          theme: _ctx.theme,
          onToggleTheme: _ctx.toggleTheme
        }, null, 8, ["colors", "currentRoute", "pages", "theme", "onToggleTheme"])),
    _createVNode(_component_RouterView, {
      theme: _ctx.theme,
      colors: _ctx.palettes[_ctx.theme],
      device: _ctx.device,
      viewData: _ctx.view.data,
      userData: _ctx.account.data,
      settings: _ctx.viewSettings,
      onAction: _ctx.manageViewAction,
      onMessageContent: _ctx.addMessage
    }, null, 8, ["theme", "colors", "device", "viewData", "userData", "settings", "onAction", "onMessageContent"])
  ], 64))
}